<template>
	<div class="banner">
		<div class="swiper-container banner-container">
			<div class="swiper-wrapper">
				<!-- :style="{backgroundImage: 'url(' + banner + ')', backgroundSize:'cover'}" -->
				<div class="swiper-slide bg-cent" v-for="(item, index) in list.list" :key="index" :style="{
            backgroundImage: 'url(' + item.img_url_temp + ')',
            backgroundSize: 'cover'
          }">
					<img class="banner-img" :src="item.img_url_temp" alt="" />
				</div>
			</div>
			<div class="hadFrom" v-if="hadFrom">
				<div class="hadFrom-main">

					<div class="flex h-100 align-end">
						<div class="tab-left px-15 py-2">
							<div v-for="(item,index) in banerList" :key='index'>
								<h5 class="tab-left-h">{{item.title}}</h5>
								<div class="tab-left-test flex align-center flex-wrap cursor-pointer">
									<template v-for="(ele,li) in item.data">
										<span :key='li' class="tab-left-span d-block" @click="navserve(index)">{{ele}}</span>
									</template>
								</div>
							</div>
						</div>
						<div class="flex-1" style="margin-bottom: 14%;">

							<p class="title" v-html="list.title"></p>
							<p class="sTitle" v-html="list.desc"></p>
							<div class="m-auto">
								<getphome></getphome>
							</div>
							

						</div>
					</div>
				</div>
			</div>
			<div class="hadFrom" v-if="!hadFrom">
				<div class="hadFrom-main">

					<div class="flex h-100 align-center">
						<div>
						</div>
						<div class="flex-1 content-test">
							<p class="title" v-html="list.title"></p>
							<p class="sTitle" v-html="list.desc"></p>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>
<script>
	import Swiper from "swiper";
	import getphome from './get-phone.vue'
	export default {
		components: {
			getphome
		},
		data() {
			return {
				phone: "",
				banerList: [{
						title: '总承包资质',
						data: ['施工总承包', '建筑工程总承包', '公路工程总承包', '电力工程总承包', '市政公用工程', '水利水电工程', '机电工程总承包']
					},
					{
						title: '专业承包资质',
						data: ['地基基础工程', '电子智能化工程', '消防设施工程', '钢结构工程', '模板脚手架工程', '装修装饰工程', '机电安装工程', '防水防腐保温工程', '公路交通工程', '城市及道路照明工程',
							'输变电工程', '水利水电机电工程'
						]
					},
					{
						title: '设计类资质',
						data: ['电力行业设计', '建设工程设计', '市政行业工程设计', '公路行业工程设计', '建筑幕墙工程设计', '照明工程设计', '建筑装饰工程设计', '智能化系统设计', '轻型钢结构工程设计']
					},
					{
						title: '其他承包资质',
						data: ['承装（修、试）资质', '安全许可证', '施工劳务资质（不分等级）']
					}
				]
				// banner: [
				// 	require("@/assets/image/banner.jpg"),
				// 	require("@/assets/image/banner1.jpg"),
				// 	require("@/assets/image/banner2.jpg")
				// ],
			};
		},
		props: {
			hadFrom: {
				type: Boolean,
				default: false,
			},
			// 最小高度
			myHeight: {
				type: Number,
				default: 300,
			},
			list: {
				type: Object,
				default: {
					title: "",
					desc: "",
					list: [],
				},
			},
		},
		methods: {
			// 点击跳转服务
			navserve(index) {
				let url = ['/contract', '/qualification', '/design', '/decoration'];
				this.$router.push(url[index]);
			},
			setMessage() {
				let source_url = window.location.href;
				if (!/^1[3456789]\d{9}$/.test(this.phone)) {
					this.$message.error("请输入正确手机号");
					return;
				}
				let data = {
					channel: 10,
					phone: this.phone,
					source_url: source_url,
				};
				if (this.$mobile) {
					data.channel = 20;
				}
				this.$api.applyOffer(data).then((res) => {
					this.$message.success("提交成功，稍后专业顾问将为你提供专业报价");
					this.phone = "";
				});
			},
			_init_swiper() {
				var swiper = new Swiper(".banner .banner-container", {
					autoplay: 2000,
					loop: false,
				});
			},
			onSearch() {},
		},
		mounted() {
			this._init_swiper();
		},
	};
</script>
<style lang="scss" scoped>
	@font-face {
		font-family: "HYYaKuHeiW";
		src: url(../../assets/font/HYYaKuHeiW.ttf);
		font-weight: normal;
		font-style: normal;
	}

	@import "@/assets/css/public.scss";

	.tab-left {
		width: 290px;
		height: 100%;
		margin-right: 110px;
		background: rgba(255, 255, 255, 0.8);

		.tab-left-h {
			font-size: 18px;
			font-family: Source Han Sans CN;
			font-weight: bold;
			color: #383D41;
			margin-bottom: 12px;
		}

		.tab-left-test {
			span {
				font-size: 12px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #6D6E73;
				margin-bottom: 10px;
				width: 48%;

				&:hover {
					color: #2070FF;
				}
			}
		}
	}

	.sTitle {
		// margin-bottom: 70px !important;
	}

	.mt-list {
		margin-bottom: 110px;

		div {
			width: 145px;
			height: 53px;
			background-color: #f85f0c;
			font-size: 26px;
			font-family: Source Han Sans CN;
			font-weight: bold;
			color: #FFFFFF;
			line-height: 53px;
			text-align: center;
		}
	}
	
	.content-test{
		width: 1200px;
		padding-left: 15%;
	}
	
	
	@media only screen and (max-width: 1024px) {
		.content-test{
			width: 100%;
			padding-left: 0%;
		}
		
		.tab-left{
			display: none;
		}
		.gitphone .message-main .content .ant-btn-primary{
			margin: auto;
		}
	}
	
	@media only screen and (max-width: 750px) {}
	
	
	@media only screen and (max-width: 481px) {
		.tab-left{
			display: none;
		}
		.content-test{
			width: auto;
			padding-left: 0;
		}
	}
</style>
